var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"d-flex justify-end mb-4"},[_c('v-toolbar',{staticClass:"mt-n2",attrs:{"flat":""}},[_c('v-toolbar-title',{staticClass:"text-body-2 text-md-h5"},[_vm._v(" Соревнования ")])],1),_c('div',{},[_c('v-btn',{staticClass:"text-uppercase text-caption text-md-subtitle-1 mb-2",attrs:{"color":"red","dark":""}},[_vm._v(" Добавить соревнование ")])],1)],1),_c('v-expansion-panels',_vm._l((3),function(i){return _c('v-expansion-panel',{key:i,staticClass:"mb-4"},[_c('v-expansion-panel-header',{staticClass:"black--text",scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" $expand ")])]},proxy:true}],null,true)},[_c('div',{},[_c('v-icon',{attrs:{"color":"red","left":""}},[_vm._v(" mdi-trophy ")]),_vm._v(" Название соревнования, дата ")],1)]),_c('v-expansion-panel-content',[_c('div',{},[_vm._v(" Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ")]),_c('div',{staticClass:"d-flex mt-2"},[_c('v-dialog',{attrs:{"persistent":"","max-width":"350"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","icon":"","color":"red"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"28"}},[_vm._v("mdi-delete")])],1)]}}],null,true),model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline text-body-1 text-md-h5"},[_vm._v(" Вы действительно хотите удалить запись об этом соревновании? ")]),_c('v-card-text',[_vm._v("Запись будет удалена из базы данных без возможности восстановления.")]),_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.deleteDialog = false}}},[_vm._v("Отмена")]),_c('v-spacer'),_c('v-btn',{attrs:{"dark":"","color":"red"},on:{"click":function($event){_vm.deleteDialog = false}}},[_vm._v("Удалить")])],1)],1)],1),_c('v-spacer'),_c('v-dialog',{attrs:{"persistent":"","max-width":"400px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","icon":"","color":"red"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"28"}},[_vm._v("mdi-pencil")])],1)]}}],null,true),model:{value:(_vm.editDialog),callback:function ($$v) {_vm.editDialog=$$v},expression:"editDialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h6"},[_vm._v("Карточка соревнований")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"mb-n6",attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-text-field',{attrs:{"label":"Название соревнований*","required":""}})],1),_c('v-col',{staticClass:"mb-n6",attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-text-field',{attrs:{"label":"Адрес и место проведения соревнований*","required":""}})],1),_c('v-col',{staticClass:"mb-n6",attrs:{"cols":"12"}},[_c('v-dialog',{ref:"dialog",refInFor:true,staticClass:"mb-6",attrs:{"return-value":_vm.competitionDate,"persistent":"","width":"350px"},on:{"update:returnValue":function($event){_vm.competitionDate=$event},"update:return-value":function($event){_vm.competitionDate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"required":"","label":"Укажите дату проведения соревнований","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.competitionDate),callback:function ($$v) {_vm.competitionDate=$$v},expression:"competitionDate"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.dateModal),callback:function ($$v) {_vm.dateModal=$$v},expression:"dateModal"}},[_c('v-date-picker',{attrs:{"color":"red","scrollable":""},model:{value:(_vm.competitionDate),callback:function ($$v) {_vm.competitionDate=$$v},expression:"competitionDate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.dateModal = false}}},[_vm._v(" Отмена ")]),_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":function($event){return _vm.$refs.dialog.save(_vm.competitionDate)}}},[_vm._v(" Принять ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-dialog',{ref:"dialog2",refInFor:true,attrs:{"return-value":_vm.competitionTime,"persistent":"","width":"350px"},on:{"update:returnValue":function($event){_vm.competitionTime=$event},"update:return-value":function($event){_vm.competitionTime=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"required":"","label":"Укажите время начала соревнований","prepend-icon":"mdi-clock-time-four-outline"},model:{value:(_vm.competitionTime),callback:function ($$v) {_vm.competitionTime=$$v},expression:"competitionTime"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.timeModal),callback:function ($$v) {_vm.timeModal=$$v},expression:"timeModal"}},[(_vm.timeModal)?_c('v-time-picker',{attrs:{"full-width":"","format":"24hr","color":"red"},model:{value:(_vm.competitionTime),callback:function ($$v) {_vm.competitionTime=$$v},expression:"competitionTime"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.timeModal = false}}},[_vm._v(" Отмена ")]),_c('v-btn',{attrs:{"text":"","color":"red"},on:{"click":function($event){return _vm.$refs.dialog2.save(_vm.competitionTime)}}},[_vm._v(" Принять ")])],1):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-textarea',{attrs:{"required":"","outlined":"","clearable":"","clear-icon":"mdi-close-circle","label":"Дополнительная информация"},model:{value:(_vm.competitionDescription),callback:function ($$v) {_vm.competitionDescription=$$v},expression:"competitionDescription"}})],1)],1)],1)],1),_c('v-card-actions',{staticClass:"mt-n8"},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.editDialog = false}}},[_vm._v(" Отмена ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":function($event){_vm.editDialog = false}}},[_vm._v(" Сохранить ")])],1)],1)],1)],1)])],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }